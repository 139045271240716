/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import './datepicker.css';
import { translateWrapper } from 'ter-localization';
import formatDatePicker from '../../config/Constants';
import calendar from '../../assets/img/brand/calendar1.png';

class Form extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      edited: false,
    };
    this.onChangeText = this.onChangeText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {
      formValue, input_key: inputKey, value, onChange,
    } = nextProps;
    if (formValue[inputKey] && formValue[inputKey] !== value) {
      onChange(formValue[inputKey]);
    }
  }

  onChangeText(text) {
    const { onChange } = this.props;
    this.setState({ edited: true });
    onChange(text);
  }

  render() {
    const {
      title, validationResult, forceValidate,
      left_col: leftCol,
      right_col: rightCol,
      input_key: inputKey,
      justifyContent,
      fontWeight,
      end_date_key: endDateKey,
      start_date_key: startDateKey,
      formValue,
      disableKey,
      value,
      showMonthYearPicker,
      t,
      filterDate,
    } = this.props;
    const { edited } = this.state;
    const width = window.innerWidth;
    return (
      <FormGroup row>
        <Col
          md={leftCol}
          style={{
            justifyContent: width < 800 ? 'flex-start' : justifyContent,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Label
            style={{
              display: 'flex',
              fontWeight: fontWeight || 'bold',
            }}
            onClick={e => e.preventDefault()}
            htmlFor="name"
          >
            {title}
          </Label>
        </Col>
        <Col md={rightCol}>
          <div style={{
            flexDirection: 'row',
            display: 'flex',
            opacity: formValue[disableKey] ? 0.2 : 1,
            zIndex: 0,
          }}
          >
            <DatePicker
              {...this.props}
              className="offwork-date__input"
              disabled={!!formValue[disableKey]}
              selected={value ? new Date(value) : ''}
              onChange={this.onChangeText}
              dateFormat={showMonthYearPicker
                ? formatDatePicker.formatDatePicker.yearMonthTime
                : formatDatePicker.formatDatePicker.dateTime}
              placeholderText={showMonthYearPicker
                ? formatDatePicker.formatDatePicker.yearMonth
                : formatDatePicker.formatDatePicker.dateTimeNormal}
              ref={(c) => { this.calendar = c; }}
              filterDate={filterDate || (date => (formValue[startDateKey]
                ? date >= new Date(formValue[startDateKey])
                : (formValue[endDateKey]
                  ? date <= new Date(formValue[endDateKey]) : date)))}
            />
            <button
              style={{
                width: 20,
                height: 20,
                position: 'absolute',
                top: 7,
                right: 23,
                outline: 0,
                borderWidth: 0,
                backgroundColor: 'transparent',
              }}
              onClick={() => this.calendar.setFocus()}
              type="button"
              alt="calendar"
            >
              <img
                style={{
                  cursor: formValue[disableKey] ? 'auto' : 'pointer',
                  width: 20,
                  height: 20,
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
                src={calendar}
                alt="true"
              />
            </button>
          </div>
          {
            !formValue[disableKey] && (
            <ValidationError
              t={t}
              validationResult={validationResult}
              inputKey={inputKey}
              edited={edited}
              forceValidate={forceValidate}
            />
            )
          }
        </Col>
      </FormGroup>
    );
  }
}

const ValidationError = ({
  validationResult, inputKey, edited, forceValidate,
}) => {
  if (!edited && !forceValidate) {
    return null;
  }

  if (validationResult && validationResult.fields && validationResult.fields[inputKey]) {
    const messages = validationResult.fields[inputKey];
    return messages.map(message => (
      <p style={{ color: 'red', marginBottom: 0 }}>{message}</p>
    ));
  }

  return null;
};

export default translateWrapper('validation')(Form);
Form.propTypes = {
  disableKey: PropTypes.string,
  justifyContent: PropTypes.string,
  t: PropTypes.func,
  onChange: PropTypes.func,
  title: PropTypes.string,
  left_col: PropTypes.number,
  right_col: PropTypes.number,
  value: PropTypes.any,
  input_key: PropTypes.string,
  validationResult: PropTypes.object,
  forceValidate: PropTypes.bool,
  fontWeight: PropTypes.string,
  end_date_key: PropTypes.string,
  start_date_key: PropTypes.string,
  formValue: PropTypes.object,
  showMonthYearPicker: PropTypes.bool,
  filterDate: PropTypes.func,
};

Form.defaultProps = {
  disableKey: null,
  justifyContent: null,
  t: null,
  onChange: null,
  title: null,
  left_col: 4,
  right_col: 8,
  value: null,
  input_key: null,
  validationResult: null,
  forceValidate: false,
  fontWeight: null,
  end_date_key: null,
  start_date_key: null,
  formValue: null,
  showMonthYearPicker: false,
  filterDate: null,
};
