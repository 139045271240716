import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form } from 'reactstrap';
import Input from './input';
import Dropdown from './dropdown';
import DatePicker from './datepicker';
import './datepicker.css';
import Info from './info';
import Checkbox from './checkbox';

class CustomForm extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(prev => ({
      ...prev,
      value: {
        ...prev.value,
        ...nextProps.value,
      },
    }));
  }

  onChange(key, value) {
    if (key) {
      this.setState(prev => ({
        value: {
          ...prev.value,
          [key]: value,
        },
      }));

      const { onChange } = this.props;
      if (onChange) {
        onChange(key, value);
      }
    }
  }

  render() {
    const {
      template: { elements }, validationResult, forceValidate, disableItems, width,
    } = this.props;
    const { value } = this.state;
    return (
      <Form style={{ width }} row>
        <Row>
          {elements && elements.map((element) => {
            switch (element.type) {
              case 'checkbox':
                return (
                  <Col xs={element.col || 12} key={element.input_key}>
                    <Checkbox
                      {...element}
                      disableItems={disableItems}
                      value={value[element.input_key]}
                      onChange={text => this.onChange(element.input_key, text)}
                      formValue={value}
                    />
                  </Col>
                );
              case 'info':
                return (
                  <Col xs={element.col || 12} key={element.input_key}>
                    <Info
                      {...element}
                      value={value[element.input_key]}
                    />
                  </Col>
                );
              case 'select':
                return (
                  <Col xs={element.col || 12} key={element.input_key}>
                    <Dropdown
                      disableItems={disableItems}
                      {...element}
                      value={value[element.input_key] || element.value}
                      formValue={value}
                      onChange={text => this.onChange(element.input_key, text)}
                      validationResult={validationResult}
                      forceValidate={forceValidate}
                    />
                  </Col>
                );
              case 'datepicker':
                return (
                  <Col xs={element.col || 12} key={element.input_key}>
                    <DatePicker
                      {...element}
                      value={value[element.input_key] || element.value}
                      formValue={value}
                      onChange={text => this.onChange(element.input_key, text)}
                      validationResult={validationResult}
                      forceValidate={forceValidate}
                    />
                  </Col>
                );

              case 'textarea':
                return (
                  <Col xs={element.col || 12} key={element.input_key}>
                    <Input
                      {...element}
                      type="textarea"
                      rows="5"
                      style={{ resize: 'none' }}
                      value={value[element.input_key] || element.value}
                      onChange={text => this.onChange(element.input_key, text)}
                      validationResult={validationResult}
                      forceValidate={forceValidate}
                    />
                  </Col>
                );

              default:
                return (
                  <Col xs={element.col || 12} key={element.input_key}>
                    <Input
                      {...element}
                      value={value[element.input_key] || element.value}
                      onChange={text => this.onChange(element.input_key, text)}
                      validationResult={validationResult}
                      forceValidate={forceValidate}
                    />
                  </Col>
                );
            }
          })}
        </Row>
      </Form>
    );
  }
}

export default CustomForm;

CustomForm.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  template: PropTypes.object,
  validationResult: PropTypes.object,
  disableItems: PropTypes.array,
  forceValidate: PropTypes.object,
  width: PropTypes.number,
};

CustomForm.defaultProps = {
  onChange: null,
  value: null,
  template: null,
  validationResult: null,
  forceValidate: false,
  disableItems: [],
  width: null,
};
