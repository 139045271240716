/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Nav, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
  AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler,
} from '@coreui/react';
import logo from '../../assets/img/brand/logo.png';
import sygnet from '../../assets/img/brand/sygnet.svg';

const propTypes = {
  history: PropTypes.object.isRequired,
};

const defaultProps = {};

class DefaultHeader extends React.PureComponent {
  render() {
    const { history } = this.props;
    const width = window.innerWidth;
    const myProfile = JSON.parse(localStorage.getItem('my-profile'));
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: logo, width: 89, height: 25, alt: 'CoreUI Logo',
          }}
          minimized={{
            src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo',
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto btn-author" navbar>
          {width > 500
          && (
          <div className="mr-1">
            {myProfile && myProfile.email}
          </div>
          )
        }
          <AppHeaderDropdown direction="down">
            <Button
              className="non-btn"
              type="button"
              onClick={() => { history.push('/logout'); }}
            >
              <i className="icon-user" />
              Log Out
            </Button>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
