import { TranslateService } from 'ter-localization';
import en from './en.json';

TranslateService.setConfig(require('./config.json'));

TranslateService.setTranslate({
  en,
});

TranslateService.setLanguage('en');

export default TranslateService;
