/* eslint-disable react/void-dom-elements-no-children */
import React, { Component } from 'react';
import {
  Col, FormGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';


class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ checked: value });
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (value !== nextProps.value) { this.setState({ checked: nextProps.value }); }
  }

  render() {
    const {
      title,
      left_col: leftCol,
      right_col: rightCol,
      onChange,
      fontWeight,
    } = this.props;
    const { checked } = this.state;
    return (
      <FormGroup
        row
        onClick={() => {
          this.setState({ checked: !checked });
          onChange(!checked);
        }}
      >
        <Col
          md={leftCol || 6}
          style={{
            fontWeight: fontWeight || 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        />
        <Col
          md={rightCol || 2}
          style={{
            alignItems: 'center',
            lineHeight: '40px',
            display: 'flex',
          }}
        >
          <input
            checked={checked}
            type="checkbox"
            style={{ cursor: 'pointer', height: 20, width: 20 }}
          />
        </Col>
        <Col
          md={4}
          style={{ cursor: 'pointer', lineHeight: '40px' }}
        >
          {title}
        </Col>
      </FormGroup>
    );
  }
}

export default Info;

Info.propTypes = {
  title: PropTypes.string,
  left_col: PropTypes.string,
  right_col: PropTypes.string,
  onChange: PropTypes.func,
  fontWeight: PropTypes.string,
  value: PropTypes.bool,
};
Info.defaultProps = {
  title: null,
  left_col: null,
  right_col: null,
  onChange: null,
  fontWeight: null,
  value: null,
};
