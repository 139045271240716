import React, { Component } from 'react';
import {
  Input,
  InputGroup, InputGroupAddon, InputGroupText, Button,
} from 'reactstrap';
import { translateWrapper } from 'ter-localization';
import PropTypes from 'prop-types';

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = { valueInput: '' };
  }

  async onChangeText(val) {
    const { onChange } = this.props;
    await this.setState({ valueInput: val });
    if (onChange) { onChange(val); }
  }

  async handleCloseSearch() {
    const { onChange } = this.props;
    await this.setState({ valueInput: '' });
    if (onChange) { onChange(''); }
  }

  render() {
    const { onChange, placeholder } = this.props;
    const { valueInput } = this.state;
    return (
      <InputGroup className="search-right">
        <Input
          type="text"
          id="SEARCH_INPUT"
          name="input1-group1"
          placeholder={placeholder}
          value={valueInput}
          onChange={(event) => {
            if (onChange) { this.onChangeText(event.target.value); }
          }}
        />
        {valueInput.length > 0 && (
        <Button
          className="card-header-action btn-close btn btn-link"
          onClick={() => this.handleCloseSearch()}
        >
          <i className="icon-close" />
        </Button>
        )}
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="fa fa-search" />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

export default translateWrapper('component_search_box')(SearchBox);

SearchBox.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.any,
};

SearchBox.defaultProps = {
  onChange: null,
  placeholder: null,
};
