export const validateEmail = (email) => {
  const errors = [];
  if (email.length === 0) {
    errors.push('Please enter a valid email address.');
  } else {
    const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = regexPattern.test(String(email).toLowerCase());
    if (result !== true) {
      errors.push('Please enter a valid email address.');
    }
  }

  return errors;
};

export default validateEmail;
