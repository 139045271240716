import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  container: {
    overflowY: 'auto',
    marginTop: 10,
    justifyContent: 'center',
    width: '100%',
  },
  listOptions: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    borderRadius: 5,
    padding: 5,
    marginTop: 10,
    backgroundColor: 'rgb(240, 243, 245)',

  },
  labelStyle: {
    cursor: 'default',
    lineHeight: '25px',
  },
};

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      searchText: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { options, searchText } = this.props;
    if (options !== nextProps.options || searchText !== nextProps.searchText) {
      this.setState({ options: nextProps.options, searchText: nextProps.searchText });
    }
  }

  render() {
    const { options, searchText } = this.state;
    const { onChecked } = this.props;
    const filterOptions = options.filter(o => o.label.replace(/\s\s+/g, ' ').toLowerCase().includes(searchText.replace(/\s\s+/g, ' ').toLowerCase()));
    return (
      <div style={styles.container}>
        {filterOptions.length ? filterOptions.map((o, i) => (
          <div
            onClick={() => onChecked(o, i)}
            style={styles.listOptions}
            role="button"
            tabIndex={i}
            onKeyPress={() => onChecked(o, i)}
          >
            <input style={{ margin: 5, lineHeight: '25px' }} checked={o.checked} type="checkbox" />
            <div style={styles.labelStyle}>{o.label}</div>
          </div>
        )) : <div className="no-options">No options.</div> }
      </div>
    );
  }
}


index.propTypes = {
  options: PropTypes.array,
  onChecked: PropTypes.func,
  searchText: PropTypes.string,
};

index.defaultProps = {
  options: [],
  onChecked: () => {},
  searchText: null,
};
