import React, { Component } from 'react';
import Select from 'react-select';
import PropsType from 'prop-types';
import {
  FormGroup,
  Col,
  Label,
  Button,
  Row,
} from 'reactstrap';
import Pagination from 'react-js-pagination';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-pagination/assets/index.css';
import DatePicker from 'react-datepicker';
import './ReportHistoryTable.css';
import './CustomPagination.css';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { checkPermissions } from '../../utils/auth';
import Constants from '../../config/Constants';
import './index.scss';

const FROMDATE = 'FROMDATE';
const TODATE = 'TODATE';
const PROGRESS = 1;
const PLAN = 2;
const REPORTHISTORY = 'REPORTHISTORY';
const BASICINFORMATION = 'BASICINFORMATION';
const REPORTMEMTER = 'REPORTMEMTER';

const countRow = (data) => {
  const objectCount = {};
  data.map((result) => {
    const objects = result.projects || result.employees;
    let progressCount = 0;
    let planCount = 0;
    let dateTimeProgressLog = 0;
    let dateTimePlanLog = 0;
    objects.map((object) => {
      let countProgressTimeOnProject = 0;
      let countPlanTimeOnProject = 0;
      const { tasks } = object;
      tasks.map((task) => {
        if (task.type === PROGRESS) {
          progressCount += 1;
          countProgressTimeOnProject += Number(task && task.time_worked);
        } else {
          planCount += 1;
          countPlanTimeOnProject += Number(task && task.time_worked);
        }
        return task;
      });
      objectCount[`${result.date}_${object.code || object.id}_${PROGRESS}_taskCount`] = progressCount;
      objectCount[`${result.date}_${object.code || object.id}_${PLAN}_taskCount`] = planCount;
      objectCount[`${result.date}_${object.code || object.id}_countProgressTimeOnProject`] = countProgressTimeOnProject;
      objectCount[`${result.date}_${object.code || object.id}_countPlanTimeOnProject`] = countPlanTimeOnProject;
      dateTimeProgressLog += countProgressTimeOnProject;
      dateTimePlanLog += countPlanTimeOnProject;
      return object;
    });
    objectCount[`${result.date}_countProgressTimeOnProject`] = dateTimeProgressLog;
    objectCount[`${result.date}_countPlanTimeOnProject`] = dateTimePlanLog;
    return result;
  });
  return objectCount;
};


class ReportHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      param: props.param,
      currentPage: 1,
      hasNext: props.hasNext,
      isHaveEmployeeHubReportHistory: false,
      isHaveProjectHubReportHistory: false,
    };
    const days = 9;
    const currentDate = new Date();
    const lastDate = new Date(currentDate.getTime() - (days * 24 * 60 * 60 * 1000));
    const { param } = this.state;
    param.from_date = lastDate;
    param.to_date = currentDate;
    this.handleClickLink = this.handleClickLink.bind(this);
  }

  async componentDidMount() {
    const { date, param } = this.props;
    if (date) {
      const newParam = {
        ...param,
        from_date: date,
        to_date: date,
      };
      this.setState({ param: newParam });
    }
    this.checkPermissionHistory();
  }


  componentWillReceiveProps(nextProps) {
    const { hasNext, param } = this.props;
    if (hasNext !== nextProps.hasNext || param !== nextProps.param) {
      this.setState({ hasNext: nextProps.hasNext, param: nextProps.param });
    }
  }

  checkPermissionHistory() {
    this.setState({
      isHaveEmployeeHubReportHistory: checkPermissions(
        [Constants.permission.EMPLOYEE_HUB_REPORT_HISTORY_VIEW],
      ),
      isHaveProjectHubReportHistory: checkPermissions(
        [Constants.permission.PROJECT_HUB_REPORT_HISTORY_VIEW],
      ),
      isHaveProjectHubInfoView: checkPermissions(
        [Constants.permission.PROJECT_HUB_BASIC_INFO_VIEW],
      ),
      isHaveReportMemberView: checkPermissions([
        [Constants.permission.PROJECT_HUB_REPORT_MEMBER_VIEW],
      ]),
      isHaveEmployeeHubBasicInfo: checkPermissions([
        [Constants.permission.EMPLOYEE_HUB_BASIC_INFO_VIEW],
      ]),
    });
  }

  renderEditButton = (indexResult) => {
    const { history } = this.props;
    const { data } = this.props;
    return (
      <Button
        pathname="button-edit"
        onClick={() => {
          history.push({
            pathname: '/personal/daily-report',
            state: { date: data[indexResult].date },
          });
        }}
      >
        <i className="icon-note" />
    Edit
      </Button>
    );
  }

  handleClickLink(indexResult, object) {
    const { history, isProjectHub, data } = this.props;
    const {
      isHaveEmployeeHubReportHistory,
      isHaveProjectHubReportHistory,
      isHaveProjectHubInfoView,
      isHaveReportMemberView,
      isHaveEmployeeHubBasicInfo,
    } = this.state;
    const id = object && object.id;
    const date = data && data[indexResult] && data[indexResult].date;
    if (isProjectHub) {
      if (isHaveEmployeeHubReportHistory) {
        history.push({
          pathname: `/employee-hub/${id}`,
          state: {
            dateFromReport: date,
            activeTab: REPORTHISTORY,
          },
        });
      } else if (isHaveEmployeeHubBasicInfo) {
        history.push({
          pathname: `/employee-hub/${id}`,
          state: {
            dateFromReport: date,
            activeTab: BASICINFORMATION,
          },
        });
      }
    } else if (isHaveProjectHubReportHistory) {
      history.push({
        pathname: `/project-hub/${id}`,
        state: {
          dateFromReport: date,
          activeTab: REPORTHISTORY,
        },
      });
    } else if (isHaveProjectHubInfoView) {
      history.push({
        pathname: `/project-hub/${id}`,
        state: {
          dateFromReport: date,
          activeTab: BASICINFORMATION,
        },
      });
    } else if (isHaveReportMemberView) {
      history.push({
        pathname: `/project-hub/${id}`,
        state: {
          dateFromReport: date,
          activeTab: REPORTMEMTER,
        },
      });
    }
  }

  renderDatePicker() {
    const { handeSelectedDatePicker } = this.props;
    const { param } = this.state;
    return (
      <FormGroup className="date-picker-employeehub" row>
        <p style={{ margin: 5 }}>From:</p>
        <Col
          md={{ size: 5 }}
          className="employee-datepicker"
          lg="3"
        >
          <Label
            className="icon-calendar icons font-xl datepicker-common"
            onClick={e => e.preventDefault()}
          >
            <DatePicker
              dateFormat={Constants.formatDatePicker.dateTime}
              name="from_date"
              selected={new Date(param && param.from_date)}
              onChange={e => handeSelectedDatePicker(e, FROMDATE)}
              filterDate={date => moment() > date}
              maxDate={new Date(param.to_date)}
            />
          </Label>
        </Col>
        <p style={{ margin: 5 }}>To:</p>
        <Col
          md={{ size: 5 }}
          className="employee-datepicker"
          lg="3"
        >
          <Label
            className="icon-calendar icons font-xl datepicker-common"
            onClick={e => e.preventDefault()}
          >
            <DatePicker
              dateFormat={Constants.formatDatePicker.dateTime}
              name="to_dates"
              selected={new Date(param && param.to_date)}
              onChange={e => handeSelectedDatePicker(e, TODATE)}
              filterDate={date => moment() > date}
              minDate={new Date(param.from_date)}
            />
          </Label>
        </Col>
      </FormGroup>
    );
  }

  render() {
    const {
      param, currentPage, hasNext,
    } = this.state;
    const isHaveEmployeeHubReportHistory = checkPermissions(
      [Constants.permission.EMPLOYEE_HUB_REPORT_HISTORY_VIEW],
    );
    const isHaveProjectHubReportHistory = checkPermissions(
      [Constants.permission.PROJECT_HUB_REPORT_HISTORY_VIEW],
    );
    const isHaveProjectHubInfoView = checkPermissions(
      [Constants.permission.PROJECT_HUB_BASIC_INFO_VIEW],
    );
    const isHaveReportMemberView = checkPermissions([
      [Constants.permission.PROJECT_HUB_REPORT_MEMBER_VIEW],
    ]);
    const isHaveEmployeeHubBasicInfo = checkPermissions([
      [Constants.permission.EMPLOYEE_HUB_BASIC_INFO_VIEW],
    ]);

    const {
      data, setDailyReport, myReportHistorys, onChange, isProjectHub,
    } = this.props;
    let objectCount;
    if (data) objectCount = countRow(data);
    if (currentPage === 1) param.offset = 0;
    return (
      <div className="card-table">
        {this.renderDatePicker()}
        <table style={{ minWidth: 1000 }} className="table table-bordered">
          {
          data && data.map((result, indexResult) => {
            const objects = result && (result.projects || result.employees);
            return (
              <tbody>
                {indexResult === 0 && (
                  <tr>
                    <th className="table-th" width="20%" colSpan="3">Date</th>
                    <th className="table-th" width="30%" colSpan="3">Progress</th>
                    <th className="table-th" width="30%" colSpan="3">Plan</th>
                    <th className="table-th" width="10%" colSpan="3">Problem</th>
                  </tr>
                )}
                {
                  objects.length === 0 && (
                  <React.Fragment>
                    <tr>
                      <td className="td-edit" width="10%" colSpan="3" rowSpan="2">
                        <tr>
                          <div>{`${moment(result && result.date).format('dddd')}`}</div>
                          <div>{`${moment(result && result.date).format('MMMM DD')}`}</div>
                        </tr>
                        <tr>
                          <div>Progress Logged: 0 hrs</div>
                          <div>Plan Logged: 0 hrs</div>
                          <div className="btn-edit">
                            {myReportHistorys && this.renderEditButton(indexResult)}
                          </div>
                        </tr>
                      </td>
                      <th width="80%" className="lineHeight-th" style={{ backgroundColor: '#D9D9D9' }} colSpan="6">{'  '}</th>
                      <th width="10%" className="lineHeight-th" style={{ backgroundColor: '#D9D9D9' }} colSpan="3">0 hrs</th>
                    </tr>
                    <tr>
                      <td width="30%" colSpan="3">
                        <tr />
                      </td>
                      <td width="30%" colSpan="3">
                        <tr />
                      </td>
                      <td width="10%" style={{ height: 45 }} colSpan="3">{' '}</td>
                    </tr>
                  </React.Fragment>
                  )
                }
                {
                  objects.map((object, indexObject) => {
                    const id = object.code || object.id;
                    const progressTimeLoggedProject = objectCount[`${result.date}_${id}_countProgressTimeOnProject`];
                    const progressTimeLoggedAtDay = objectCount[`${result.date}_countProgressTimeOnProject`];
                    const planTimeLoggedAtDay = objectCount[`${result.date}_countPlanTimeOnProject`];
                    return (
                      <React.Fragment>
                        <tr>
                          { indexObject === 0
                          && (
                          <td className="td-edit" width="10%" rowSpan="1000" colSpan="3">
                            <tr>
                              <div>{`${moment(result && result.date).format('dddd')}`}</div>
                              <div>{`${moment(result && result.date).format('MMMM DD')}`}</div>
                            </tr>
                            <tr>
                              <div>{`Progress Logged: ${progressTimeLoggedAtDay || 0} hrs`}</div>
                              <div>{`Plan Logged: ${planTimeLoggedAtDay || 0} hrs`}</div>
                              <div className="btn-edit">
                                {myReportHistorys && this.renderEditButton(indexResult)}
                              </div>
                            </tr>
                          </td>
                          )}
                          <th
                            width="80%"
                            className="lineHeight-th"
                            style={
                              ((isHaveEmployeeHubReportHistory && isHaveProjectHubReportHistory)
                              || isHaveEmployeeHubBasicInfo)
                              || (isProjectHub
                                && (isHaveProjectHubInfoView || isHaveReportMemberView))
                                ? {
                                  backgroundColor: '#D9D9D9', cursor: 'pointer', color: '#20A8D8', textDecorationLine: 'underline',
                                } : { backgroundColor: '#D9D9D9' }}
                            colSpan="6"
                            onClick={() => (object && object.informations && object.informations.company_name === 'Admin' ? null : this.handleClickLink(indexResult, object))}
                          >
                            {object.name || object.informations.company_name}
                          </th>
                          <th width="10%" className="lineHeight-th" style={{ backgroundColor: '#D9D9D9' }} colSpan="3">
                            {`${progressTimeLoggedProject || 0} hrs`}
                          </th>
                        </tr>
                        <tr>
                          <td width="30%" colSpan="3">
                            {object.tasks.map(task => task.type === PROGRESS && (
                            <tr>
                              {`• ${task.task_id || ''} ${task.task_id ? '-' : ''} ${task.summary}: ${task.time_worked} hrs (${task.previous_progress || '0'}% -> ${task.current_progress || ''}%)`}
                            </tr>
                            ))}
                          </td>
                          <td width="30%" colSpan="3">
                            {object.tasks.map(task => task.type === PLAN && (
                            <tr>
                              {`• ${task.task_id || ''} ${task.task_id ? '-' : ''} ${task.summary}: ${task.time_worked} hrs (${task.current_progress || '0'}% -> ${task.planned_progress || ''}%)`}
                            </tr>
                            ))}
                          </td>
                          <td width="15%" colSpan="3" className="textarea-none">{object.problem}</td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                }
              </tbody>
            );
          })
        }
        </table>
        <div className="my-request__pagination">
          <Row>
            <Col>
              <div className="select-perpage">
                <div style={{ position: 'relative' }} className="select-perpage__info">
                  <Select
                    className="select-pagination"
                    menuPlacement="top"
                    options={[{ label: 10, value: 10 },
                      { label: 20, value: 20 },
                      { label: 50, value: 50 },
                      { label: 100, value: 100 }]}
                    value={{ label: (param && param.limit) || 10, value: (param && param.limit) || 10 }}
                    onChange={(option) => {
                      param.limit = option.value;
                      param.offset = 0;
                      if (onChange) { onChange(param); }
                      setDailyReport(param);
                      this.setState({ param, currentPage: 1 });
                    }}
                  />
                  <p>{`Showing ${data && data.length ? param.offset + 1 : 0} - ${(param.offset + param.limit > hasNext ? hasNext : param.offset + param.limit) || (data && data.length)} of ${hasNext || (data && data.length)} records`}</p>
                </div>
              </div>
            </Col>
            <Col
              xl={{
                size: 6,
              }}
              lg={{
                size: 6,
              }}
              md={{
                size: 6,
              }}
              sm={{
                size: 12,
              }}
              xs={{
                size: 12,
              }}
            >
              {
              hasNext > param.limit ? (
                <Pagination
                  totalItemsCount={hasNext > param.limit ? hasNext : false}
                  activePage={currentPage}
                  onChange={(option) => {
                    param.offset = option === 1 ? 0 : (param.limit * option) - param.limit;
                    setDailyReport(param);
                    if (onChange) { onChange(param); }
                    this.setState({ currentPage: option, param });
                  }}
                  itemsCountPerPage={param.limit}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              ) : <div />
            }
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportHistoryTable);

ReportHistoryTable.propTypes = {
  data: PropsType.array.isRequired,
  setDailyReport: PropsType.func.isRequired,
  param: PropsType.object.isRequired,
  handeSelectedDatePicker: PropsType.func.isRequired,
  hasNext: PropsType.object.isRequired,
  history: PropsType.object.isRequired,
  myReportHistorys: PropsType.object.isRequired,
  isProjectHub: PropsType.bool.isRequired,
  date: PropsType.string.isRequired,
  onChange: PropsType.func.isRequired,
};
