import Constants from './config/Constants';

const nav = {
  items: [
    // ==================================>
    {
      name: 'Personal',
      icon: 'icon-user',
      id: 'PERSONAL_MENU',
      children: [
        {
          name: 'Daily Report',
          url: '/personal/daily-report',
          id: 'DAILY_REPORT_MENU',
          permissions: [Constants.permission.DAILY_REPORT_CRUD],
        },
        {
          name: 'My Report',
          url: '/personal/my-report-history',
          id: 'MYREPORT_MENU',
          permissions: [Constants.permission.DAILY_REPORT_CRUD],
        },
        {
          name: 'My Requests',
          url: '/personal/my-requests',
          id: 'MY_REQUESTS',
          permissions: [Constants.permission.MY_REQUEST_CRUD],
        },
        {
          id: 'MYPROFILE_MENU',
          name: 'My Profile',
          url: '/personal/my-profile',
          permissions: [Constants.permission.MY_PROFILE_VIEW],
        },
      ],
    },
    {
      name: 'Attendance',
      icon: 'icon-calendar',
      id: 'ATTENDANCE_MENU',
      children: [
        {
          name: 'Requests',
          url: '/attendance/requests',
          id: 'ATTENDANCE_REQUESTS_MENU',
          permissions: [
            Constants.permission.REQUEST_VIEW,
          ],
        },
      ],
    },
    // ==================================>
    {
      name: 'Employee',
      icon: 'icon-people',
      id: 'EMPLOYEE_MENU',
      children: [
        {
          id: 'EMPLOYEE_LIST_MENU',
          name: 'Employee List',
          url: '/employee',
          permissions: [Constants.permission.EMPLOYEE_VIEW],
        },
        {
          id: 'CONTRACT_CURRENT',
          name: 'Latest Contracts',
          url: '/latest-contract',
          permissions: [Constants.permission.EMPLOYEE_CRUD],
        },
      ],
    },
    // ==================================>
    {
      id: 'RECRUITMENT_MENU',
      name: 'Recruitment',
      icon: 'icon-briefcase',
      children: [
        {
          id: 'RECRUITMENT_LIST_MENU',
          name: 'Candidate List',
          url: '/recruitment/candidate',
          permissions: [Constants.permission.CANDIDATE_LIST],
        },
      ],
    },
    // ==================================>
    {
      id: 'PROJECT_MENU',
      name: 'Project',
      icon: 'icon-briefcase',
      children: [
        {
          id: 'PROJECT_LIST_MENU',
          name: 'Project List',
          url: '/project-list',
          permissions: [Constants.permission.PROJECT_VIEW],
        },
        {
          id: 'PROJECT_HUB_MENU',
          name: 'Project Hub',
          url: '/project-hub',
          permissions: [
            Constants.permission.PROJECT_HUB_BASIC_INFO_VIEW,
            Constants.permission.PROJECT_HUB_REPORT_HISTORY_VIEW,
            Constants.permission.PROJECT_HUB_REPORT_MEMBER_VIEW,
          ],
        },
      ],
    },
    // ==================================>
    {
      id: 'SYSTEM_ACCESS_MENU',
      name: 'System Access',
      icon: 'icon-lock',
      children: [
        {
          id: 'LOGIN_ACCOUNT_MENU',
          name: 'Login Accounts',
          url: '/login_accounts',
          permissions: [Constants.permission.LOGIN_ACCOUNTS_VIEW_LIST],
        },
        {
          id: 'ROLES_MENU',
          name: 'Roles',
          url: '/roles',
          permissions: [Constants.permission.ROLES_VIEW],
        },
      ],
    },
  ],
};


export default nav;
