import _ from 'lodash';

let changeListeners = [];
let user = null;

const getUser = () => user;

const setUser = (user1) => {
  user = user1;
  changeListeners.forEach(l => l(user));
};

const getPermissions = () => {
  let resultPermissions = {};
  if (user && user.roles && user.roles.items) {
    resultPermissions = user.roles.items.reduce((total, item) => {
      if (item.permissions) {
        try {
          const permissionObject = JSON.parse(item.permissions);
          return Object.keys(permissionObject).reduce((subTotal, itemSub) => {
            if (permissionObject[itemSub] === true) {
              subTotal[itemSub] = true; //eslint-disable-line
            }
            return subTotal;
          }, total);
        } catch (error) {
          console.log(error);
        }
      }
      return true;
    }, {});

    if (user && user.permissions) {
      try {
        const permissionsUser = JSON.parse(user.permissions);
        if (permissionsUser) {
          resultPermissions = _.merge(resultPermissions, permissionsUser);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  return resultPermissions;
};

const addListener = (object) => {
  if (changeListeners.every(item => item !== object)) {
    changeListeners.push(object);
  }
};

const removeListener = (object) => {
  changeListeners = changeListeners.filter(item => item !== object);
};

// eslint-disable-next-line max-len
const checkPermissions = (permissions = []) => permissions.some(permission => getPermissions()[permission]);

const saveUser = () => {
  localStorage.setItem('userInfo', JSON.stringify(user));
};

const loadUser = () => {
  const userInfo = localStorage.getItem('userInfo');
  return userInfo;
};

const userInfo = {
  getUser,
  setUser,
  addListener,
  removeListener,
  getPermissions,
  checkPermissions,
  saveUser,
  loadUser,
};

export default userInfo;
