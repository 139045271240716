import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'reactstrap';
import PropsType from 'prop-types';

const getBase64 = (image, callback) => {
  // eslint-disable-next-line
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(image);
};

function MyDropzone(props) {
  const [img, setImg] = useState(0);
  const { avatarUrl, onChange } = props;
  const uploadButton = (
    <i
      style={{ fontSize: 130 }}
      alt=""
      className="fa fa-user-circle fa-lg"
    />
  );

  const types = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/bmp',
  ];

  const beforeUpload = (file) => {
    const isLt5M = file && file.size / 1000000 >= 5;
    if (!types.includes(file.type) || isLt5M) {
      // eslint-disable-next-line
      alert('Image must be in jpg or png format and less than 5mb');
      return false;
    }
    return true;
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles && acceptedFiles[0];
    if (beforeUpload(file)) {
      onChange(file);
      setImg(getBase64(file, e => setImg(e)));
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      style={{
        marginBottom: (avatarUrl || img) ? 30 : 0,
      }}
    >
      {(avatarUrl || img) && (
      <Button
        close
        color="danger"
        onClick={() => {
          onChange(null);
          setImg(null);
        }}
        style={{
          width: 20,
          height: 20,
          display: 'flex',
        }}
      />
      )}
      <div
        {...props}
        {...getRootProps()}
        style={{ cursor: 'pointer' }}
      >
        <input
          {...getInputProps()}
          multiple={false}
        />
        {(avatarUrl || img) ? (
          <img
            style={{
              height: 130,
              width: 130,
              borderRadius: '50%',
              objectFit: 'cover',
              border: '1px solid black',
              borderColor: 'black',
            }}
            src={img || avatarUrl}
            alt="avatar"
          />
        ) : uploadButton}
      </div>
    </div>
  );
}

export default MyDropzone;
MyDropzone.propTypes = {
  history: PropsType.object.isRequired,
  location: PropsType.object.isRequired,
  match: PropsType.object.isRequired,
  avatarUrl: PropsType.any.isRequired,
  t: PropsType.any.isRequired,
  onChange: PropsType.any.isRequired,
};
