import { axiosCallApi } from './index';


export const getMyRequests = (params = {
  limit: 2,
}) => axiosCallApi('api/v1/request/my_requests', 'get', params);

export const getRequestDetail = params => axiosCallApi(`api/v1/request/${params.id}`, 'get', params);

export const resubmit = params => axiosCallApi(`api/v1/request/${params.id}/${params.type}/resubmit`, 'put', params);

export const createNewRemote = params => axiosCallApi('api/v1/request/remote', 'post', params);

export const createNewOff = params => axiosCallApi('api/v1/request/off', 'post', params);

export const discardRequest = params => axiosCallApi(`api/v1/request/${params.id}/discard`, 'post', params);

export const getRequests = params => axiosCallApi('api/v1/request/get-requests', 'get', params);

export const searchRequest = params => axiosCallApi('api/v1/request/get-requests', 'get', params);

export default getMyRequests;
