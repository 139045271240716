import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  container: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
  },
  input: {
    width: '100%',
    outline: 'none',
    borderRadius: 5,
    paddingLeft: 15,
    margin: 5,
    border: '1px solid #ccc',
    height: 40,
  },
  listChecked: {
    borderRadius: 5,
    padding: 5,
    margin: 5,
    backgroundColor: '#f0f3f5',
  },
};

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
    };
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { options } = this.props;
    if (options !== nextProps.options) {
      this.setState({ options: nextProps.options });
    }
  }

  onClick() {
    const { onShowList } = this.props;
    onShowList();
    this.input.focus();
  }

  onChange(e) {
    const { onChangeInput } = this.props;
    onChangeInput(e.target.value);
  }

  render() {
    const { options } = this.state;
    return (
      <div
        style={styles.container}
        onClick={this.onClick}
        tabIndex="0"
        onKeyPress={this.onClick}
        role="button"
        onMouseOver={this.onClick}
        onFocus={this.onClick}
      >
        {options.filter(o => o.checked).map(o => <div style={styles.listChecked}>{o.label}</div>)}
        <input
          ref={(i) => { this.input = i; }}
          style={styles.input}
          onChange={this.onChange}
          placeholder="Add Project"
        />
      </div>
    );
  }
}

index.propTypes = {
  options: PropTypes.array,
  onChangeInput: PropTypes.func,
  onShowList: PropTypes.func,
};

index.defaultProps = {
  options: [],
  onChangeInput: () => {},
  onShowList: () => {},
};
