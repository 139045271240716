
import validator from 'validator';

export const checkContainOnlyWhiteSpace = (value) => {
  if (!value || value.toString() === '') {
    return false;
  }

  if (!value.replace(/\s/gu, '').length) {
    return true;
  }
  return false;
};

export const isEmpty = (value) => {
  if (!value || value.toString().trim().length === 0) {
    return true;
  }
  return false;
};

export const checkValidURL = value => /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(value)
          || /^(https?:\/\/)?((w{3}\.)?)twitter.com\/.*/i.test(value)
          || /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(value)
          || /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i.test(value);

export const checkValidEmail = (value) => {
  if (value && value.length > 0) { return validator.isEmail(value) && /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(value) && (value.indexOf(' ') === -1); }

  return true;
};

export const checkImageFormat = value => /\.(jpg|jpeg|png)$/i.test(value);

//export const checkValidHTTPLink = value => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gu.test(value); //eslint-disable-line

export const checkLengthString = (value, min = -1, max = -1) => {
  if (min === 0 && max > 0) {
    if (value) { return ((value.length >= min) && (value.length <= max)); }
    return true;
  }
  if (min >= 0 && max >= 0) {
    return ((value.length >= min) && (value.length <= max));
  }
  if (min >= 0 && max < 0) {
    return (value.length >= min);
  }
  if (min < 0 && max >= 0) {
    return (value.length <= max);
  }
  return false;
};

export const isContainSpecialCharacters = (value) => {
  const specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
  for (let count = 0; count < specialChars.length; count += 1) {
    if (value.indexOf(specialChars[count]) > -1) {
      return true;
    }
  }
  return false;
};

export const isValidNumberFormat = value => /^-?\d+(?:[.]\d*?)?$/.test(value);
