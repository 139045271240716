import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastr';
import _ from 'lodash';
import Input from '../../components/Input';
import './login.css';
import authApi from '../../apis/api-user.auth';
import validate from './validator';
import Errors from '../../components/Errors/index';
import * as apis from '../../apis';
import { setPermissions } from '../../utils/auth';
import userWrapper from '../../components/UserService';
import Constants from '../../config/Constants';


class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: null,
      errorsmessage: null,
      submitting: false,
    };
    this.login = this.login.bind(this);
    this.myProfile = this.myProfile.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.state && location.state.isSuccess) {
      this.showSuccess(location.state.message);
    }
  }

  goToForgotPassword = (e) => {
    const { history } = this.props;
    e.preventDefault();
    history.push('/forgot-password');
  }

  myProfile = async () => {
    const { setUser } = this.props;
    const result = await apis.myProfile();
    if (result) {
      localStorage.setItem('my-profile', JSON.stringify(result));
      setPermissions(result);
      setUser(result);
      this.redirectAfterLogin(result);
    } else {
      console.log('err', result);
    }
  }

  showSuccess = (mess) => {
    this.toastContainer.success(
      <strong>{mess}</strong>, '', { closeButton: true },
    );
  }

  redirectAfterLogin(content) {
    const { history } = this.props;
    const permissions = {};
    const roles = content.roles.items;
    const myPermissions = JSON.parse(content.permissions);

    _.merge(permissions, myPermissions);
    _.forEach(roles, (role) => {
      const tmpPermissions = JSON.parse(role.permissions);
      _.merge(permissions, tmpPermissions);
    });
    if (Constants.permission.DAILY_REPORT_CRUD in permissions) {
      history.push('/personal/daily-report');
    } else if (Constants.permission.MY_PROFILE_VIEW in permissions) {
      history.push('/personal/my-profile');
    } else {
      history.push('/personal/error');
    }
  }

  async login(e) {
    e.preventDefault();
    const { t } = this.props;
    const data = this.state;
    const validationResult = validate({
      email: data.email,
      password: data.password,
    });
    if (validationResult.email.length > 0 || validationResult.password.length > 0) {
      this.setState({
        errors: validationResult,
        errorsmessage: t('email_or_password_is_inlvalid'),
      });
    } else {
      this.setState({
        submitting: true,
        errors: null,
      });
      const result = await authApi.login({
        email: data.email,
        password: data.password,
      });
      const statusRequest = await result;
      const contentResult = await result.json();
      if (contentResult.isSuccess === false) {
        this.setState({
          submitting: false,
          errorsmessage: t('email_or_password_is_inlvalid'),
        });
      }
      if (!statusRequest.ok && statusRequest.status !== 200) {
        this.setState({
          submitting: false,
          errors: statusRequest.statusText,
        });
      } else {
        this.setState({
          submitting: false,
          errors: null,
        });
        localStorage.setItem('email', contentResult.email);
        localStorage.setItem('token', contentResult.accessToken);
        this.myProfile();
      }
    }
  }

  render() {
    const {
      submitting, errors, errorsmessage, validationResult,
    } = this.state;
    const { state } = this;
    const bind = statePath => ({
      value: state[statePath],
      onChange: e => this.setState({ [statePath]: e.target.value }),
    });

    return (
      <div className="login">
        <ToastContainer
          ref={(ref) => { this.toastContainer = ref; }}
          className="toast-top-right"
        />
        <div className="login__background">
          <img alt="background" src="../../assets/img/bg-nexus.jpg" />
        </div>
        <form
          className="login__container"
        >
          <div className="justify-content-center row">
            <div className="col-lg-4">
              <div className="card-group">
                <div className="card">
                  <div className="card-body">
                    <div className="c-header">
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                    </div>
                    <div className="errors-message">
                      {errorsmessage}
                      {validationResult}
                    </div>
                    <div className="form-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon-user" />
                        </span>
                        <Input
                          id="EMAIL_INPUT"
                          classStyle="form-control login__form--input"
                          name="email"
                          placeholder="johndoe@nexusfrontier.tech"
                          {...bind('email')}
                          disabled={submitting}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon-lock" />
                        </span>
                        <Input
                          id="PASSWORD_INPUT"
                          type="password"
                          classStyle="form-control login__form--input validation-color"
                          name="password"
                          placeholder="Password"
                          {...bind('password')}
                          disabled={submitting}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <button
                          id="SUBMIT_BUTTON"
                          type="submit"
                          className="btn px-4 btn-primary"
                          onClick={this.login}
                          disabled={submitting}
                        >
                        LOGIN
                        </button>
                      </div>
                      <div className="text-right col-6">
                        <div
                          className="px-0 btn btn-link"
                          role="presentation"
                        >
                          <a href="/#/forgot-password" id="FORGOT_PASSWORD_LINK">Forgot Password ?</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors && errors.message && errorsmessage && <Errors errors={errors} />}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}


export default userWrapper(translate('error')(LoginPage));
LoginPage.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object.isRequired,
  setUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

LoginPage.defaultProps = {
  t: () => {},
};
