import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import './resetpassword.css';
import stringquery from 'stringquery';
import { decode } from 'base-64';
import {
  Input, Form, Row, Col, Card, CardBody, FormGroup,
} from 'reactstrap';
import { ToastContainer } from 'react-toastr';
import * as apis from '../../apis/index';
import LoadingFull from '../../components/LoadingFull';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: '',
        password_confirmation: '',
        token: '',
      },
      isVerified: true,
      errors: [],
      submitting: false,
      loading: true,
      isSuccess: false,
      isTokenExpired: null,
    };
    this.submit = this.submit.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  componentDidMount() {
    const { formData } = this.state;
    const { history, location } = this.props;
    const search = stringquery(location.search);
    if (search && search.m && search.token) {
      const email = decode(search.m);
      this.setState({
        formData: {
          ...formData,
          email,
          token: search.token,
        },
        loading: false,
      });
    } else {
      history.push('/login');
    }
    this.checkTokenStatus();
  }

  goToLogin = (e) => {
    e.preventDefault();
    const { history } = this.props;
    history.push('/login');
  }

  showSuccess = (mess) => {
    this.toastContainer.success(
      <strong>{mess}</strong>, '', { closeButton: true },
    );
  }

  async checkTokenStatus() {
    const { location } = this.props;
    const search = stringquery(location.search);
    if (search && search.m && search.token) {
      const email = decode(search.m);
      const params = {
        email,
        token: search.token,
      };
      const resultCheck = await apis.checkExpire(params);
      this.setState({ isTokenExpired: !resultCheck.isSuccess });
    }
  }

  async submit(e) {
    e.preventDefault();
    e.stopPropagation();
    try {
      const { formData } = this.state;
      const { history } = this.props;
      const result = await apis.resetPassword(formData);
      e.preventDefault();
      if (result && result.isSuccess) {
        await history.push({
          pathname: '/login',
          state: {
            isSuccess: true,
            message: 'Password has been reset.',
          },
        });
      } else if (result && result.invalidParams) {
        this.setState({ errors: result.invalidParams });
      }
    } catch (event) {
      console.log(event);
    }
  }

  toggleLoading() {
    const { loading } = this.state;
    this.setState({ loading: !loading });
  }

  render() {
    const {
      submitting, isSuccess, isVerified, formData, loading, errors, isTokenExpired,
    } = this.state;
    const { history } = this.props;
    return (
      <Col className="reset-password">
        <ToastContainer
          ref={(ref) => { this.toastContainer = ref; }}
          className="toast-top-right"
        />
        <LoadingFull show={loading} />
        {isVerified && (
        <React.Fragment>
          <Col className="login__background">
            <img alt="background" src="../../assets/img/bg-nexus.jpg" />
          </Col>
          {
            isTokenExpired === true
            && (
              <Form className="reset-password__container" onSubmit="return false">
                <Row className="justify-content-center">
                  <Col className="col-md-4">
                    <Col className="card-group">
                      <Card className="card">
                        <CardBody className="card-body">
                          {!isSuccess && (
                          <Col>
                            <h3 className="reset-password__title">Reset Password </h3>
                            <p className="reset-password__paragraph--left"> Sorry this link has expired. Please reset password again. </p>
                            <Col className="text-center">
                              <button
                                id="SUBMIT_BUTTON"
                                type="submit"
                                className="btn btn-primary forgot-password__form--button button-ok"
                                onClick={() => history.push('/login')}
                              >
                                OK
                              </button>
                            </Col>
                          </Col>
                          )}
                          {isSuccess && (
                          <Col>
                            <h3 className="reset-password__title"> RESET PASSWORD </h3>
                            <Col className="reset-password__message--success">
                              <h5 style={{ margin: 0 }}>Your password has been reset.</h5>
                            </Col>
                            <p className="reset-password__paragraph">
                            Please
                              <a href="/#/login">click here to log in</a>
                            </p>
                          </Col>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Col>
                </Row>
              </Form>
            )
          }
          {
            isTokenExpired === false
            && (
            <Form className="reset-password__container" onSubmit="return false">
              <Row className="justify-content-center">
                <Col className="col-md-4">
                  <Col className="card-group">
                    <Card className="card">
                      <CardBody className="card-body">
                        {!isSuccess && (
                        <Col>
                          <h3 className="reset-password__title">Reset Password </h3>
                          <p className="reset-password__paragraph--left"> Please choose a new password for this user account. </p>
                          <ul className="reset-password-ul">
                            {errors && errors.length > 0 && errors.map(err => <li className="text-danger">{err.message}</li>)}
                          </ul>
                          <FormGroup>
                            <Input
                              classStyle="form-control reset-password__form--input"
                              name="email"
                              placeholder="New Password"
                              disabled={submitting}
                              type="password"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...formData,
                                    password: e.target.value,
                                  },
                                });
                              }}
                              value={formData.password}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              classStyle="form-control reset-password__form--input"
                              name="email"
                              placeholder="Confirm New Password"
                              disabled={submitting}
                              type="password"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...formData,
                                    password_confirmation: e.target.value,
                                  },
                                });
                              }}
                              value={formData.confirm_password}
                            />
                          </FormGroup>
                          <Row>
                            <Col className="text-left col-6">
                              <Col className="text-left px-0 btn btn-link">
                                <a href="/#/login">Back to Login</a>
                              </Col>
                            </Col>
                            <Col className="text-right col-6">
                              <button
                                type="submit"
                                className="px-4 btn btn-primary reset-password__form--button"
                                onClick={this.submit}
                                disabled={submitting}
                              >
SUBMIT

                              </button>
                            </Col>
                          </Row>
                        </Col>
                        )}
                        {isSuccess && (
                        <Col>
                          <h3 className="reset-password__title"> RESET PASSWORD </h3>
                          <Col className="reset-password__message--success">
                            <h5 style={{ margin: 0 }}>Your password has been reset.</h5>
                          </Col>
                          <p className="reset-password__paragraph">
                          Please
                            <a href="/#/login">click here to log in</a>
                          </p>
                        </Col>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Col>
              </Row>
            </Form>
            )
          }

        </React.Fragment>
        )}
      </Col>
    );
  }
}

export default withRouter(ResetPassword);

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
