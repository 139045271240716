/*eslint-disable */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  // AppSidebarNav,
} from '@coreui/react';
import { AppSidebarNav } from '../../components'
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import userWrapper from '../../components/UserService';
import { getMyRequests, getRequests } from '../../apis/my-request';

class DefaultLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      myRequests: 0,
      attendanceRequests: 0,
    }
    this.changeMyRequestsBadge = this.changeMyRequestsBadge.bind(this);
    this.changeAttendanceRequestsBadge = this.changeAttendanceRequestsBadge.bind(this);
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token) {
      this.getMyRequestsBadge();
      this.getAttendanceRequestsBadge();
    }
  }

  async getMyRequestsBadge() {
    const myRequests = await getMyRequests({
      status: "Pending"
    })
    this.setState({
      myRequests: myRequests && myRequests.hasNext
    })
  }

  changeMyRequestsBadge(number) {
    this.setState({
      myRequests: number
    })
  }

  async getAttendanceRequestsBadge() {
    const myRequests = await getRequests({
      status: "Pending"
    })
    this.setState({
      attendanceRequests: myRequests && myRequests.hasNext
    })
  }

  changeAttendanceRequestsBadge(number) {
    this.setState({
      attendanceRequests: number
    })
  }

  async componentWillMount() {
    await this.checkToken();
  }

  async checkToken() {
    const { history } = this.props;
    const token = localStorage.getItem('token');
    if(token === null) {
      history.push('/login');
    }
  }

  filteredNavigation() {
    const permissions = this.props.getPermissions();
    const filtered = navigation.items.map(item => {
      return {
        ...item,
        children: item.children.map((child) => {
          if (child.name === "My Requests") {
            child.badge =  { variant: 'info', text: this.state.myRequests  };
          }
          if (child.name === "Requests") {
            child.badge =  { variant: 'info', text: this.state.attendanceRequests  };
          }
          return child;
        })
        .filter(child => !child.permissions || child.permissions.find(permission => permissions[permission] === true))
      }
    }).filter(item => item.children.length > 0);
    return {
      items: filtered
    };
  }

  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader {...this.props} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={this.filteredNavigation()} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes()} />
            <Container fluid>
              <Switch>
                {routes().map((route, idx) => route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={(props) => {
                  return <route.component 
                  {...props}
                  changeMyRequestsBadge={this.changeMyRequestsBadge}
                  changeAttendanceRequestsBadge={this.changeAttendanceRequestsBadge}
                  />
                }} />) : (null),)}
              </Switch>
            </Container>
          </main>
        </div>
      </div>
    );
  }
}

export default userWrapper(DefaultLayout);
