import React from 'react';
import PropTypes from 'prop-types';
import './forgotpassword.css';
import {
  Form, Row, Col, Card, CardBody, FormGroup,
} from 'reactstrap';
import authApi from '../../apis/api-user.auth';
import Input from '../../components/Input';
import { validateEmail } from './validator';
import LoadingFull from '../../components/LoadingFull';

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: null,
      submitting: false,
      showSuccessMessage: false,
      loading: false,
    };
    this.submit = this.submit.bind(this);
  }

  goToLogin = (e) => {
    const { history } = this.props;
    e.preventDefault();
    history.push('/login');
  }

  async submit(e) {
    e.preventDefault();
    const { email } = this.state;
    const validationResult = validateEmail(email);
    if (validationResult.length > 0) {
      this.setState({
        errors: {
          email: validationResult,
        },
      });
    } else {
      this.setState({
        submitting: true,
        errors: null,
      });
      this.toggleLoading();
      const res = await authApi.forgotPassword(email);
      this.toggleLoading();
      if (res.error) {
        this.setState({
          submitting: false,
          errors: res.data,
        });
      } else {
        this.setState({
          submitting: false,
          showSuccessMessage: true,
        });
      }
    }
  }

  toggleLoading() {
    const { loading } = this.state;
    this.setState({ loading: !loading });
  }

  render() {
    const {
      submitting, errors, showSuccessMessage, loading,
    } = this.state;
    const { history } = this.props;
    return (
      <Col className="forgot-password">
        <LoadingFull show={loading} />
        <Col className="login__background">
          <img alt="background" src="../../assets/img/bg-nexus.jpg" />
        </Col>
        <Form className="forgot-password__container" onSubmit={this.submit}>
          <Row className="justify-content-center">
            <Col className="col-md-4">
              <Col className="card-group">
                <Card>
                  <CardBody>
                    {!showSuccessMessage && (
                    <Col>
                      <h3 className="forgot-password__title">Forgot Password</h3>
                      <p className="forgot-password__paragraph">Enter your email address below to request a reset password email.</p>
                      <FormGroup>
                        <Input
                          id="EMAIL_INPUT"
                          errorID="EMAIL_ERROR MESSAGE"
                          classStyle="form-control forgot-password__form--input"
                          name="email"
                          placeholder="johndoe@nexusfrontier.tech"
                          disabled={submitting}
                          errors={errors}
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                        />
                      </FormGroup>
                      <Row>
                        <Col className="text-left col-6">
                          <button
                            id="BACK_TO_LOGIN_LINK"
                            type="button"
                            className="px-0 btn btn-link"
                            onClick={this.goToLogin}
                          >
                            Back to Login
                          </button>
                        </Col>
                        <Col className="text-right col-6">
                          <button
                            type="submit"
                            className="btn btn-primary forgot-password__form--button px-4"
                            onClick={this.submit}
                            disabled={submitting}
                          >
                          SUBMIT
                          </button>
                        </Col>
                      </Row>
                    </Col>
                    )}
                    {showSuccessMessage && (
                    <Col>
                      <h3 className="forgot-password__title">Forgot Password</h3>
                      <p className="forgot-password__paragraph">
                        An email has been sent to your email address.
                        Click the link in the email to reset your password.
                        <br />
                        <br />
                        {'If you don\'t see the email, check junk, spam, or other folders.'}
                      </p>
                      <Col className="text-center">
                        <button
                          id="SUBMIT_BUTTON"
                          type="submit"
                          className="btn btn-primary forgot-password__form--button button-ok"
                          onClick={() => history.push('/login')}
                        >
                          OK
                        </button>
                      </Col>
                    </Col>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>
        </Form>
      </Col>
    );
  }
}

ForgotPassword.propTypes = {
  history: PropTypes.object.isRequired,
};
