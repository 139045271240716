import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Label from './label';
import List from './list';
import './index.css';

const styles = {
  container: {
    height: 'auto',
    padding: 10,
    boxShadow: '0 0 2px 0 rgba(0,0,0,.25)',
    borderRadius: 10,
  },
};

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
    };
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  onChangeInput(searchText) {
    this.setState({ searchText });
  }

  render() {
    const { searchText } = this.state;
    return (
      <div
        className="dropdown"
        style={styles.container}
      >
        <Label
          onChangeInput={this.onChangeInput}
          {...this.props}
        />
        <div className={searchText ? 'dropdown-content-show ' : 'dropdown-content'}>
          <List
            {...this.props}
            onFocus={this.showList}
            searchText={searchText}
          />
        </div>

      </div>
    );
  }
}

index.propTypes = {
  onChecked: PropTypes.func,
  searchText: PropTypes.string.isRequired,
};

index.defaultProps = {
  onChecked: () => {},
};
