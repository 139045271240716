import React, { Component } from 'react';
import Select from 'react-select';

import {
  FormGroup, Col, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { filterOption } from '../../utils/base';

const customStyles = {
  control: base => ({
    ...base,
    borderColor: '#E3E7EA',
  }),
};

class Dropdown extends Component {
  constructor(props, context) {
    super(props, context);
    const { mapPropsToState, value } = props;

    this.state = {
      selectedValue: value,
      options: props.options,
      params: mapPropsToState ? mapPropsToState(props) : undefined,
      edited: false,
    };
  }

  async componentDidMount() {
    const { request, formValue, ...props } = this.props;
    if (request) {
      const result = await request({
        ...props,
        formValue,
      });
      this.setState(prev => ({
        ...prev,
        options: result,
      }));
    }
  }

  async componentWillReceiveProps(nextProps) {
    const {
      mapPropsToState, request, value,
    } = nextProps;
    const { disableItems } = this.props;
    const { params, selectedValue } = this.state;

    if (value !== selectedValue) {
      this.setState({ selectedValue: value });
    }

    if (mapPropsToState && request) {
      const nextParams = mapPropsToState(nextProps);
      if (nextParams !== params) {
        const result = await request(nextProps);
        this.setState(prev => ({
          ...prev,
          options: result,
          params: nextParams,
        }), () => this.onChangeText(''));
      }
    }

    if (disableItems !== nextProps.disableItems) {
      const result = await request(nextProps);
      const search = _.map(nextProps.disableItems, e => ({ ...e, isDisabled: true }));
      this.setState(prev => ({
        ...prev,
        options: _.unionBy(search, result, 'value'),
      }));
    }
  }


  onChangeText(text) {
    const { onChange } = this.props;

    if (onChange) {
      onChange(text);
    }

    this.setState({
      selectedValue: text,
    });
  }

  render() {
    const {
      title, validationResult, forceValidate,
      left_col: leftCol,
      right_col: rightCol,
      input_key: inputKey,
      label_width: labelWidth,
      titleLast, lastCol,
      justifyContent,
      row, fontWeight,
      ...other
    } = this.props;

    const {
      options, selectedValue, edited,
    } = this.state;
    const width = window.innerWidth;

    return (
      <FormGroup row={row}>
        {title && (
        <Col
          lg={leftCol || 6}
          style={{
            justifyContent: width < 800 ? 'flex-start' : justifyContent,
            display: 'flex',
            alignItems: 'center',
            width: labelWidth,
          }}
        >
          <Label
            style={{
              fontWeight: fontWeight || 'bold',
            }}
            htmlFor="name"
          >
            {title}

          </Label>
        </Col>
        )}
        <Col lg={rightCol || 6}>
          <Select
            styles={customStyles}
            {...other}
            options={options}
            filterOption={filterOption}
            onChange={selectedOption => this.onChangeText(selectedOption.value)}
            value={options.find(option => selectedValue === option.value) === undefined ? '' : options.find(option => selectedValue === option.value)}
          />
          <ValidationError
            validationResult={validationResult}
            inputKey={inputKey}
            edited={edited}
            forceValidate={forceValidate}
          />
        </Col>
        {titleLast && (
          <Col md={lastCol} style={{ display: 'flex', alignItems: 'center' }}>
            <Label style={{ fontWeight: 'bold' }} htmlFor="name">{titleLast}</Label>
          </Col>
        )}
      </FormGroup>
    );
  }
}

const ValidationError = ({
  validationResult, inputKey, edited, forceValidate,
}) => {
  if (!edited && !forceValidate) {
    return null;
  }

  if (validationResult && validationResult.fields && validationResult.fields[inputKey]) {
    const messages = validationResult.fields[inputKey];
    return messages.map(message => (
      <p style={{ color: 'red', marginBottom: 0 }}>{message}</p>
    ));
  }

  return null;
};

export default Dropdown;

Dropdown.propTypes = {
  title: PropTypes.string,
  left_col: PropTypes.number,
  right_col: PropTypes.number,
  request: PropTypes.func,
  mapPropsToState: PropTypes.func,
  onChange: PropTypes.func,
  formValue: PropTypes.object,
  value: PropTypes.number,
  input_key: PropTypes.string,
  validationResult: PropTypes.object,
  forceValidate: PropTypes.bool,
  options: PropTypes.array,
  titleLast: PropTypes.string,
  lastCol: PropTypes.number,
  label_width: PropTypes.number,
  disableItems: PropTypes.array,
  justifyContent: PropTypes.string,
  row: PropTypes.array,
  fontWeight: PropTypes.string,
};

Dropdown.defaultProps = {
  title: '',
  left_col: 4,
  right_col: 8,
  request: null,
  mapPropsToState: null,
  onChange: null,
  formValue: null,
  value: null,
  input_key: null,
  validationResult: null,
  forceValidate: false,
  options: [],
  titleLast: null,
  lastCol: 0,
  label_width: null,
  disableItems: null,
  justifyContent: null,
  row: null,
  fontWeight: null,
};
