const permission = {
  DAILY_REPORT_CRUD: 'daily_report.crud',
  MY_PROFILE_VIEW: 'my_profile.view',
  REPORT_HISTORY_VIEW: 'report_history.view',
  EMPLOYEE_VIEW: 'employee.view',
  EMPLOYEE_CRUD: 'employee.crud',
  EMPLOYEE_HUB_BASIC_INFO_VIEW: 'employee_hub_basic_info.view',
  EMPLOYEE_HUB_REPORT_HISTORY_VIEW: 'employee_hub_report_history.view',
  PROJECT_VIEW: 'project.view',
  PROJECT_CRUD: 'project.crud',
  PROJECT_HUB_BASIC_INFO_VIEW: 'project_hub_basic_info.view',
  PROJECT_HUB_REPORT_HISTORY_VIEW: 'project_hub_report_history.view',
  PROJECT_HUB_REPORT_MEMBER_VIEW: 'project_hub_report_member.view',
  PROJECT_HUB_REPORT_MEMBER_CRUD: 'project_hub_report_member.crud',
  LOGIN_ACCOUNTS_VIEW_LIST: 'login_accounts.view_list',
  LOGIN_ACCOUNT_VIEW_DETAIL: 'login_accounts.view_detail',
  LOGIN_ACCOUNT_UPDATE: 'login_accounts.update',
  LOGIN_ACCOUNT_RESET_PASSWORD: 'login_accounts.reset_password',
  CANDIDATE_LIST: 'candidates.list',
  CANDIDATE_LIST_CRUD: 'candidates.crud',
  CANDIDATE_LIST_INFO: 'candidate_hub_basic_information.view',
  CANDIDATE_LIST_JOURNEY: "candidate_hub_journey.view",
  CANDIDATE_LIST_REF: "candidate_hub_references.view",
  ROLES_VIEW: 'roles.view',
  ROLES_DETAIL: 'roles.detail',
  ROLES_CRUD: 'roles.crud',
  MY_REQUEST_VIEW: 'my_request.view',
  MY_REQUEST_CRUD: 'my_request.crud',
  REQUEST_DETAIL_UPDATE: 'request.view_update',
  REQUEST_VIEW: 'request.view',
  REQUEST_UPDATE: 'request.update',
};

const formatDatePicker = {
  dateTimeNormal: 'YYYY/MM/DD',
  dateTime: 'yyyy/MM/dd',
  yearMonthTime: 'yyyy/MM',
  yearMonth: 'YYYY/MM',
  dateTimeMidleLine: 'YYYY-MM-DD',
  dateTimeMothMidleLine: 'YYYY-MM',
  dateTimeNormalWithDay: 'YYYY/MM/DD - ddd',

};

export default {
  permission,
  formatDatePicker,
};
