import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import _ from 'lodash';
import 'react-table/react-table.css';
import {
  Button,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { decamelize } from 'humps';
import CustomPagination from './CustomPagination';
import './Main.scss';
import { axiosCallApi } from '../../apis/index';

/**
 * Send request to server to get table data
 *
 * @param {Int} pageSize
 * @param {page} page
 * @param {Array} sorted
 * @param {} filtered
 * @param {String} url
 * @param {String} keyword keyword for multiple field search
 * @param {Object} customField list for customize filtered columns and filtered value
 * @param {Array[String]} bunchSearch list of column to search by keyword
 */

const handleDirection = (sorted) => {
  if (sorted.length > 0) {
    return sorted[0].desc ? 'asc' : 'desc';
  }
  return 'asc';
};
const handleOrder = sorted => (sorted.length > 0 ? decamelize(sorted[0].id) : 'created_at');

const requestData = (pageSize,
  page,
  sorted,
  filtered,
  url,
  keyword,
  customField,
  bunchSearch,
  queryFilter) => {
  const offset = page * pageSize;
  const order = handleOrder(sorted);
  const direction = handleDirection(sorted);
  const params = {
    limit: pageSize,
    offset,
    order,
    direction,
    query: keyword,
    customField: JSON.stringify(customField),
    ...queryFilter,
  };

  return axiosCallApi(url, 'get', params);
};

class Table extends React.PureComponent {
  constructor(props) {
    super(props);
    const { url } = props;
    this.state = {
      data: [],
      pages: null,
      total: null,
      url: url || '',
      loading: true,
      keyword: '',
      typingTimeout: 0,
      activePage: 1,
    };
    this.fetchData = this.fetchData.bind(this);
    this.addButtonAction = this.addButtonAction.bind(this);
    this.forceUpdate = this.forceUpdate.bind(this);
    this.renderFilterZone = this.renderFilterZone.bind(this);
    this.handleOnchange = this.handleOnchange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { url } = this.state;
    if (nextProps.url !== url) {
      this.setState({ url: nextProps.url });
    }
  }

  onPageChange(page) {
    this.setState({
      pages: page,
    }, () => {
      this.refReactTable.fireFetchData();
    });
  }

  getCurrentPages = (res) => {
    const limit = res.limit || 0;
    const offset = res.offset || 0;
    const pages = offset / limit;
    return pages;
  }

  handleOnchange(event) {
    const target = event.currentTarget;
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    this.setState({
      [target.name]: target.value,
      typingTimeout: setTimeout(() => {
        this.onPageChange(0, 10);
        this.forceUpdate();
        this.setState({ keyword: target.value });
      }, 300),
    });
  }

  forceUpdate() {
    this.refReactTable.fireFetchData();
  }

  async fetchData(state) {
    const {
      url,
      data,
      pages,
      loading,
      keyword,
      ...customField
    } = this.state;
    const {
      bunchSearch,
      additionalFilter,
      queryFilter,
    } = this.props;
    const extraFilterField = {
      ...customField,
      ...additionalFilter,
    };

    /* Whenever the table model changes, or the user sorts or changes pages,
    this method gets called and passed the current table model. */

    /* You can set the `loading` prop of the table to true to use
    the built-in one or show you're own loading bar if you want. */

    await this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    const res = await requestData(
      state.pageSize,
      state.pages,
      state.sorted,
      state.filtered,
      url,
      keyword,
      extraFilterField,
      bunchSearch,
      queryFilter,
    );
    this.setState({
      loading: false,
    });
    if (res) {
      this.setState({
        data: res.items || [],
        pages: this.getCurrentPages(res) || 0,
        total: res.hasNext || 0,
      });
    }
  }

  addButtonAction() {
    const { addButtonAction } = this.props;
    if (addButtonAction && typeof addButtonAction === 'function') {
      addButtonAction();
    }
  }

  renderAddButton() {
    const { labelCreateNew } = this.props;
    let createNewLabel = 'Create new';
    if (labelCreateNew) {
      createNewLabel = labelCreateNew;
    }
    return (
      <Col xs="6" className="row header-common">
        {this.renderButton('info', createNewLabel, this.addButtonAction, 'add-button')}
      </Col>
    );
  }

  renderButton = (color = 'success', title = '', onClick, className = '') => (
    <Button
      color={color}
      onClick={onClick}
      className={className}
    >
      {title}
    </Button>
  )

  renderFilterZone() {
    const {
      bunchSearch,
      bunchSearchPlaceholder,
      customFieldSearch,
    } = this.props;

    const { keyword } = this.state;
    if (!bunchSearch && !customFieldSearch) {
      return null;
    }

    const content = [];

    if (customFieldSearch) {
      _.forEach(customFieldSearch, (field) => {
        if (field.type === 'select') {
          content.push(
            <Input type="select" name={field.name} onChange={this.handleOnchange}>
              {
                field.options.map(item => (
                  <option value={item.value} key={item.value}>{item.title}</option>
                ))
              }
            </Input>,
          );
        }
      });
    }

    if (bunchSearch) {
      content.push(
        <InputGroup>
          <Input
            type="text"
            placeholder={bunchSearchPlaceholder || 'Enter keyword'}
            name="keyword"
            value={keyword}
            onChange={this.handleOnchange}
          />
          {keyword.length > 0
            && (
            <Button
              color="link"
              className="card-header-action btn-close"
              onClick={() => {
                this.setState({ keyword: '' }, () => {
                  this.forceUpdate();
                });
              }}
            >
              <i className="icon-close" />
            </Button>
            )
          }
          <InputGroupAddon addonType="append">
            <InputGroupText><i className="fa fa-search" /></InputGroupText>
          </InputGroupAddon>
        </InputGroup>,
      );
    }

    return (
      content.map(c => (
        <Col xs="6" md="6" lg="6" key={c} className="row header-common search-right">
          {c}
        </Col>
      ))
    );
  }

  render() {
    const {
      data, pages, loading, total, activePage, keyword,
    } = this.state;

    const {
      columns,
      hasAddButton,
      filterable,
      showPagination,
      fixedRow,
      defaultSorted,
      defaultPageSize,
      columnFields,
      searchKey,
    } = this.props;

    const filterDatas = (
      columnFields && searchKey && _.filter(data,
        item => _.find(columnFields,
          field => _.includes(String(item[field]).toLowerCase(), searchKey)))) || data;
    return (
      <Row>
        <Col xs="12">
          {hasAddButton && this.renderAddButton()}
          {this.renderFilterZone()}
          <ReactTable
            PaginationComponent={CustomPagination}
            ref={(refReactTable) => { this.refReactTable = refReactTable; }}
            columns={columns}
            manual /* Forces table not to paginate or sort
                    automatically,so we can handle it server-side */
            keyword={keyword}
            data={filterDatas}
            activePage={activePage}
            pages={pages} // Display the total number of pages
            loading={loading} // Display the loading overlay when we need it
            onFetchData={this.fetchData} // Request new data when things change
            filterable={filterable}
            defaultPageSize={defaultPageSize || 10}
            className="-striped -highlight datatable col-12"
            showPagination={showPagination}
            minRows={fixedRow ? 0 : 0}
            defaultSorted={defaultSorted}
            showPagePerSize
            total={total}
            onPageChange={this.onPageChange}
          />
        </Col>
      </Row>
    );
  }
}

Table.defaultProps = {
  showPagination: true,
  columns: [],
  hasAddButton: false,
  filterable: false,
  fixedRow: false,
  defaultSorted: [],
  url: '',
  addButtonAction: false,
  labelCreateNew: '',
  bunchSearch: '',
  bunchSearchPlaceholder: '',
  customFieldSearch: '',
  defaultPageSize: 10,
  columnFields: '',
  searchKey: '',
};

Table.propTypes = {
  showPagination: PropTypes.bool,
  columns: PropTypes.array,
  hasAddButton: PropTypes.bool,
  filterable: PropTypes.bool,
  fixedRow: PropTypes.bool,
  defaultSorted: PropTypes.array,
  url: PropTypes.string,
  addButtonAction: PropTypes.bool,
  labelCreateNew: PropTypes.string,
  bunchSearch: PropTypes.string,
  bunchSearchPlaceholder: PropTypes.string,
  customFieldSearch: PropTypes.string,
  defaultPageSize: PropTypes.number,
  columnFields: PropTypes.any,
  searchKey: PropTypes.any,
};

export default Table;
Table.propTypes = {
  additionalFilter: PropTypes.func.isRequired,
  queryFilter: PropTypes.array.isRequired,
};
