import React, { PureComponent } from 'react';
import {
  Col, FormGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';

class Info extends PureComponent {
  render() {
    const {
      justifyContent,
      title,
      value,
      left_col: leftCol,
      titleRight,
      right_col: rightCol,
      fontWeight,
      style,
    } = this.props;
    const width = window.innerWidth;
    return (
      <FormGroup style={{ marginBottom: 0 }} row>
        <Col
          md={leftCol || 6}
          style={{
            justifyContent: width < 800 ? 'flex-start' : justifyContent,
            fontWeight: fontWeight || 'bold',
            display: 'flex',
            alignItems: 'center',
            lineHeight: '40px',
          }}
        >
          {title}
        </Col>
        <Col
          md={rightCol || 6}
          lg={titleRight && '4'}
          style={{
            lineHeight: '40px',
            ...style,
          }}
        >
          {value || ' '}
        </Col>
      </FormGroup>
    );
  }
}

export default Info;

Info.propTypes = {
  justifyContent: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  left_col: PropTypes.string,
  titleRight: PropTypes.string,
  right_col: PropTypes.string,
  fontWeight: PropTypes.string,
  style: PropTypes.object,
};

Info.defaultProps = {
  justifyContent: null,
  title: null,
  value: null,
  left_col: null,
  titleRight: null,
  right_col: null,
  fontWeight: null,
  style: null,
};
