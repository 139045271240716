import React, { Component } from 'react';
// import { browserHistory } from 'react-router';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import './i18n';
// Containers
// Pages
import PropTypes from 'prop-types';
import { Page404, Page500, Register } from './views/Pages';
import {
  Login, ForgotPassword, ResetPassword, DefaultLayout,
} from './containers';
import userWrapper from './components/UserService';
import { myProfile } from './apis';
import { setPermissions } from './utils/auth';
import './localization';

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  async componentDidMount() {
    const token = localStorage.getItem('token');
    const { setUser } = this.props;
    if (token) {
      const result = await myProfile();
      localStorage.setItem('my-profile', JSON.stringify(result));
      setPermissions(result);
      setUser(result);
    }
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/forgot-password" name="Forgot Password" component={ForgotPassword} />
          <Route exact path="/reset-password" name="Reset Password" component={ResetPassword} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default userWrapper(App);

App.propTypes = {
  setUser: PropTypes.func.isRequired,
};
