import axios from 'axios';
import { baseUrl } from '../utils';
import Auth from '../middlewares/Auth';

/**
 * Fetch api endpoint
 * @param {String} endpoint Endpoint api
 * @param {String} method method in list method http accept
 * @param {Object} params Param to fetch
 * @param {String} accessToken Access token to auth
 * @return {Object} Object response
 */
export function callApi(endpoint, method = 'get', params, headerParams = {}) {
  let fullUrl;

  let options = {};
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Auth.getToken()}`,
    ...headerParams,
  };

  options = {
    method,
    headers,
    timeout: 60000,
  };
  if (method === 'get' && Object.keys(params).length > 0) {
    fullUrl = baseUrl(endpoint, params);
  } else {
    fullUrl = endpoint.indexOf('http') === 0 ? endpoint : baseUrl(endpoint);
    if (method !== 'get') {
      options.body = JSON.stringify(params);
    }
  }

  return fetch(fullUrl, options)
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (
        !response.ok
        || (response.status && response.status !== 200 && response.status !== 201)
      ) {
        return Promise.reject(json);
      }
      return json;
    })
    .then(
      response => response,
      (error) => {
        if (error.message === 'Network request failed') {
          throw new Error(999); // No network
        } else if (error.errorCode === 1009) {
          window.location.href = '/#/404';
        } else if (error.errorCode === 1003) {
          window.location.href = '/';
        } else {
          // throw new Error(error.code || 'Something bad happened')
          return Promise.reject(error);
        }
        return false;
      },
    )
    .catch((err) => {
      console.log(err);
    });
}

export async function axiosCallApi(endpoint, method = 'get', params, headerParams = {}) {
  let fullUrl = '';
  if (method === 'get' && params && Object.keys(params).length > 0) {
    fullUrl = baseUrl(endpoint, params);
  } else {
    fullUrl = baseUrl(endpoint, {});
  }

  const axiosSetup = {
    url: fullUrl,
    method,
    timeout: 60000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Auth.getToken()}`,
      ...headerParams,
    },
  };
  if (method === 'get') {
    axiosSetup.params = params;
  } else {
    axiosSetup.data = params;
  }

  try {
    const result = await axios(axiosSetup);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    if (err && err.response && err.response.data) {
      if (!err.response.data.isSuccess && err.response.data.errorCode === 1003 && err.response.data.detail === 'Not signed in') {
        window.location.href = '/#/logout';
      }
      return err.response.data;
    }
  }
  return true;
}

export const listEmployee = params => axiosCallApi('api/v1/employee', 'get', params);
export const getAllEmployees = params => axiosCallApi('api/v1/employee/list_all', 'get', params);
export const createEmployee = params => axiosCallApi('api/v1/employee', 'post', params);
export const updateEmployee = (id, params) => axiosCallApi(`api/v1/employee/${id}`, 'put', params);
export const updateEmployeeForm = (id, params) => axiosCallApi(`api/v1/employee/${id}/update`, 'post', params);
export const getEmployeeDetail = id => axiosCallApi(`api/v1/employee/${id}`, 'get', {});
export const viewEmployee = id => axiosCallApi(`api/v1/employee/${id}`, 'get', {});
export const viewEmployeeMemberView = id => axiosCallApi(`api/v1/employee/${id}/base`, 'get', {});
export const deleteEmployee = id => axiosCallApi(`api/v1/employee/${id}`, 'delete', {});
export const archivedEmployee = (id, params) => axiosCallApi(`api/v1/employee/${id}/archive`, 'post', params);
export const reEstimate = params => axiosCallApi(`api/v1/employee/${params.id}/archive`, 'put', params);
export const deleteImage = id => axiosCallApi(`api/v1/employee/${id}/profile-image`, 'delete');

export const listRole = params => axiosCallApi('api/v1/permissions', 'get', params);
export const listPermissions = () => axiosCallApi('api/v1/permissions/lists_configs', 'get', {});
export const createRole = params => axiosCallApi('api/v1/permissions', 'post', params);
export const updateRole = (id, params) => axiosCallApi(`api/v1/permissions/${id}`, 'put', params);
export const getRoleDetail = id => axiosCallApi(`api/v1/permissions/${id}`, 'get', {});
export const deleteRole = id => axiosCallApi(`api/v1/permissions/${id}`, 'delete', {});
export const getRolePermissionSettings = id => axiosCallApi(`api/v1/permissions/${id}/permissions-settings`, 'get', {});
export const updateRolePermission = (id, params) => axiosCallApi(`api/v1/permissions/${id}/permissions`, 'put', params);

// Account Login
export const listAccounts = params => axiosCallApi('api/v1/accounts', 'get', params);
export const getDetailAccountLogin = id => axiosCallApi(`api/v1/accounts/${id}`, 'get', {});
export const updateAccountLogin = (id, params = {}) => axiosCallApi(`api/v1/accounts/${id}`, 'put', params);
export const myProfile = () => axiosCallApi('api/v1/my-profile', 'get', {});

// Auth
export const sendForgotPassword = (params = {}) => axiosCallApi('api/v1/forgot-password', 'post', params);
export const resetPassword = (params = {}) => axiosCallApi('api/v1/reset-password', 'post', params);
export const checkExpire = (params = {}) => axiosCallApi('api/v1/token/check-expire', 'post', params);

// department
export const listsDepartments = (params = {}) => axiosCallApi('api/v1/departments', 'get', params);

// positions
export const listsPositions = (params = {}) => axiosCallApi('api/v1/positions', 'get', params);

// national
export const listsNational = (params = {}) => axiosCallApi('api/v1/nationalities', 'get', params);

// Bankname
export const listsBankname = (params = {}) => axiosCallApi('api/v1/banks', 'get', params);

// Hospitalname
export const listsHospitalname = (params = {}) => axiosCallApi('api/v1/hospitals', 'get', params);

// Office
export const listsOffice = (params = {}) => axiosCallApi('api/v1/company-offices', 'get', params);

// Project
export const listProjects = (params = {}) => axiosCallApi('api/v1/projects', 'get', params);
export const listAllProject = (params = {}) => axiosCallApi('api/v1/projects/list_all', 'get', params);
export const projectAssignedMe = () => axiosCallApi('api/v1/projects/assigned-me', 'get');
export const createProjects = (params = {}) => axiosCallApi('api/v1/projects', 'post', params);
export const updateProjects = (id, params = {}) => axiosCallApi(`api/v1/projects/${id}`, 'put', params);
export const getProjectDetail = id => axiosCallApi(`api/v1/projects/${id}`, 'get', {});
export const viewProject = id => axiosCallApi(`api/v1/projects/${id}`, 'get', {});
export const deleteProject = id => axiosCallApi(`api/v1/projects/${id}`, 'delete', {});

// Client
export const listClient = (params = {}) => axiosCallApi('api/v1/clients/list_all', 'get', params);
export const createClient = (params = {}) => axiosCallApi('api/v1/clients', 'post', params);

// Team
export const listTeam = (params = {}) => axiosCallApi('api/v1/teams/list_all', 'get', params);

// Type
export const listType = (params = {}) => axiosCallApi('api/v1/types/list_all', 'get', params);

// Daily report
export const getLatestDate = () => axiosCallApi('api/v1/daily_reports/get-next-date-recent', 'get');
export const addDailyReport = (params = {}) => axiosCallApi('api/v1/daily_reports', 'post', params);
export const getReportHistoryEmployee = (params = {}) => axiosCallApi('api/v1/daily_reports/employee_reports', 'get', params);
export const getPreReport = (param = {}) => axiosCallApi('api/v1/daily_reports/my-reports', 'get', param);
export const getReportHistoryProject = (params = {}) => axiosCallApi('api/v1/daily_reports/project_reports', 'get', params);
export const deleteAllReport = (params = {}) => axiosCallApi('api/v1/daily_reports/clean', 'delete', params);

// report member
export const getAllRoles = () => axiosCallApi('api/v1/project-roles/all', 'get', {});
export const getMemers = (id, params) => axiosCallApi(`api/v1/projects/${id}/get-members`, 'get', params);
export const addMember = (id, params) => axiosCallApi(`api/v1/projects/${id}/add-member`, 'post', params);
export const deleteMember = (id, params) => axiosCallApi(`api/v1/projects/${id}/remove-member`, 'delete', params);

// contract
export const getContracts = params => axiosCallApi('api/v1/contracts', 'get', params);
export const getCurrentContracts = params => axiosCallApi('api/v1/employee/contracts', 'get', params);
export const getContract = id => axiosCallApi(`api/v1/contracts/${id}`, 'get');
export const addContract = params => axiosCallApi('api/v1/contracts', 'post', params);
export const deleteContract = id => axiosCallApi(`api/v1/contracts/${id}`, 'delete', id);
export const editContract = (id, params) => axiosCallApi(`api/v1/contracts/${id}`, 'put', params);

// dependant
export const getDepenDant = params => axiosCallApi('api/v1/dependants', 'get', params);
export const addDepenDant = params => axiosCallApi('api/v1/dependants', 'post', params);
export const editDepenDant = params => axiosCallApi(`api/v1/dependants/${params.user_id}`, 'put', params);
export const deleteDepenDant = params => axiosCallApi(`api/v1/dependants/${params.id}`, 'delete', params);

// attachment
export const addAttachment = params => axiosCallApi(`api/v1/dependants/${params.id}/attachment`, 'post', params);
export const deleteAttachment = params => axiosCallApi(`api/v1/dependants/${params.id}/attachment`, 'put', params);
