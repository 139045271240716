import React from 'react';
import UserService from './service';

const wrapper = Class => class TERLocalizationWrapper extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.ismounted = false;
  }

  componentDidMount() {
    this.ismounted = true;
    UserService.addListener(this.handleChange);
  }

  componentWillUnmount() {
    this.ismounted = false;
    UserService.removeListener(this.handleChange);
  }

  handleChange() {
    if (this.ismounted) {
      this.forceUpdate();
    }
  }

  render() {
    const { children } = this.props;
    return (
      <Class
        {...this.props}
        user={UserService.getUser()}
        setUser={UserService.setUser}
        getPermissions={UserService.getPermissions}
        checkPermissions={UserService.checkPermissions}
      >
        {children}
      </Class>
    );
  }
};

export default wrapper;
