import React from 'react';
import './index.css';

function LoadingFull(props) {
  let showLoading = false;
  // eslint-disable-next-line react/prop-types
  const { show } = props;
  if (show) {
    showLoading = true;
  }
  return (
    <div>
      {showLoading && (
      <div className="loader-wrapper">
        <div className="loader" />
      </div>
      )}
    </div>
  );
}

export default LoadingFull;
