import React from 'react';
import Loadable from 'react-loadable';

import _ from 'lodash';
import { checkPermissions } from './utils/auth';

function Loading() {
  return <div>Loading...</div>;
}

const Users = Loadable({
  loader: () => import('./views/Users/Users'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./views/Users/User'),
  loading: Loading,
});

const MyProfile = Loadable({
  loader: () => import('./containers/Personal/MyProfile'),
  loading: Loading,
});

const ErrorPage = Loadable({
  loader: () => import('./containers/Personal/Error'),
  loading: Loading,
});

const MyDailyReport = Loadable({
  loader: () => import('./containers/Personal/MyReportHistory'),
  loading: Loading,
});

const MyRequests = Loadable({
  loader: () => import('./containers/Personal/MyRequests'),
  loading: Loading,
});

const EmployeeHub = Loadable({
  loader: () => import('./containers/Employee/EmPloyeeHub'),
  loading: Loading,
});

const Employee = Loadable({
  loader: () => import('./containers/Employee/EmployeeList'),
  loading: Loading,
});

const AddEmployee = Loadable({
  loader: () => import('./containers/Employee/AddEmployee'),
  loading: Loading,
});

const ConstractCurrent = Loadable({
  loader: () => import('./containers/Employee/ConstractCurrent'),
  loading: Loading,
});

const Roles = Loadable({
  loader: () => import('./containers/Management/SystemAdmin/Role/RoleList'),
  loading: Loading,
});

const RolesEdit = Loadable({
  loader: () => import('./containers/Management/SystemAdmin/Role/FormRole'),
  loading: Loading,
});

const RolesCreate = Loadable({
  loader: () => import('./containers/Management/SystemAdmin/Role/FormAdd'),
  loading: Loading,
});

const LoginAccounts = Loadable({
  loader: () => import('./containers/LoginAccounts/LoginAccountsList'),
  loading: Loading,
});

const LoginAccountsEdit = Loadable({
  loader: () => import('./containers/LoginAccounts/EditForm'),
  loading: Loading,
});

const Project = Loadable({
  loader: () => import('./containers/Project/ProjectList'),
  loading: Loading,
});

const ProjectCreate = Loadable({
  loader: () => import('./containers/Project/AddProject'),
  loading: Loading,
});

const ProjectHub = Loadable({
  loader: () => import('./containers/Project/ProjectHub/index'),
  loading: Loading,
});

const DailyReport = Loadable({
  loader: () => import('./containers/Personal/DailyReport'),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import('./components/Logout'),
  loading: Loading,
});

const Requests = Loadable({
  loader: () => import('./containers/Attendance/Requests'),
  loading: Loading,
});

const Candidate = Loadable({
  loader: () => import('./containers/Recruitment/CandidateList'),
  loading: Loading,
});

const AddCandidate = Loadable({
  loader: () => import('./containers/Recruitment/AddCandidate'),
  loading: Loading,
});

const CandidateHub = Loadable({
  loader: () => import('./containers/Recruitment/CandidateHub'),
  loading: Loading,
});


// const ConstractCurrent = Loadable({
//   loader: () => import('./containers/Employee/ConstractCurrent'),
//   loading: Loading,
// });

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/', exact: true, name: '', component: DailyReport,
  },
  {
    path: '/users', exact: true, name: 'Users', component: Users,
  },
  {
    path: '/users/:id', exact: true, name: 'User Details', component: User,
  },
  {
    path: '/personal/my-profile', exact: true, name: 'Personal / My Profile', component: MyProfile,
  },
  {
    path: '/personal/error', exact: true, name: 'Error Page', component: ErrorPage,
  },
  {
    path: '/personal/my-report-history', exact: true, name: 'Report History', component: MyDailyReport,
  },
  {
    path: '/personal/daily-report', exact: true, name: 'Personal / Daily Report', component: DailyReport,
  },
  {
    path: '/personal/my-requests', exact: true, name: 'Personal / My Requests', component: MyRequests,
  },
  {
    path: '/employee/create', exact: true, name: 'Add employee', component: AddEmployee,
  },
  {
    path: '/employee', exact: true, name: 'Employee List', component: Employee,
  },
  {
    path: '/employee/:id/edit', exact: true, name: 'Edit employee', component: AddEmployee,
  },
  {
    path: '/employee-hub', exact: true, name: 'Employee Hub', component: EmployeeHub,
  },
  {
    path: '/employee-hub/:id?', exact: true, name: 'Employee Details', component: EmployeeHub,
  },
  {
    path: '/roles', exact: true, name: 'System Access / Roles List', component: Roles,
  },
  {
    path: '/roles/create', exact: true, name: 'Create Roles', component: RolesCreate,
  },
  {
    path: '/roles/:id/edit', exact: true, name: 'Edit Roles', component: RolesEdit,
  },
  {
    path: '/login_accounts', exact: true, name: 'System Access / Login Accounts', component: LoginAccounts,
  },
  {
    path: '/login_accounts/:id/edit', exact: true, name: 'Account Settings', component: LoginAccountsEdit,
  },
  {
    path: '/project-list', exact: true, name: 'Project List', component: Project,
  },
  {
    path: '/project-list/create', exact: true, name: 'Create Project', component: ProjectCreate,
  },
  {
    path: '/project-list/:id/edit', exact: true, name: 'Edit Project', component: ProjectCreate,
  },
  {
    path: '/project-list/:id/view/edit', exact: true, name: 'Edit View Project', component: ProjectCreate,
  },
  {
    path: '/project-hub', exact: true, name: 'Project Hub', component: ProjectHub,
  },
  {
    path: '/project-hub/:id?', exact: true, name: 'Project Details', component: ProjectHub,
  },
  {
    path: '/latest-contract', exact: true, name: 'Latest Contracts', component: ConstractCurrent,
  },
  {
    path: '/logout', exact: true, name: 'Logout', component: Logout, exceptCheckAuth: true,
  },
  {
    path: '/attendance/requests', exact: true, name: 'Attendance Requests', component: Requests,
  },
  {
    path: '/recruitment/candidate', exact: true, name: 'Candidate List', component: Candidate,
  },
  {
    path: '/recruitment/candidate/hub/:tab/:id/edit', exact: true, name: 'Candidate Edit', component: AddCandidate,
  },
  {
    path: '/recruitment/candidate/add', exact: true, name: 'Add Candidate', component: AddCandidate,
  },
  {
    path: '/recruitment/candidate/hub/:tab/:id', exact: true, name: 'Candidate Hub', component: CandidateHub,
  },
];

const filteredRoute = () => {
  const resultRouter = [];
  _.forEach(routes, (route) => {
    const tmp = route;
    if (tmp.permissions) {
      if (tmp && checkPermissions(tmp.permissions) === false) {
        return;
      }
    }
    resultRouter.push(tmp);
  });
  return resultRouter;
};

export default filteredRoute;
