import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { Label } from 'reactstrap';

import './CustomPagination.css';

const pageOption = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

const displayItemPagination = (per, total, page) => {
  let from;
  let to;
  from = (per * page + 1) - per;
  if (total === 0) { from = 0; }
  to = (page * per);
  if ((total - from) < per) { to = total; }
  return (
    <Label>{`Showing ${from} - ${to} of ${total} records`}</Label>
  );
};

export default class CustomPagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPageOption: { value: 10, label: '10' },
      totalItem: 1,
      activePage: 1,
      currentKeyword: '',
    };
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const { total, keyword } = nextProps;
      const { currentKeyword } = this.state;
      if (currentKeyword !== keyword) this.state.activePage = 1;
      this.state.currentKeyword = keyword;
      this.setState({
        totalItem: total,
      });
    }
  }

  onChangePage(e) {
    const { onPageChange } = this.props;
    const { selectedPageOption } = this.state;
    onPageChange(e - 1, selectedPageOption.value);
    this.setState({
      activePage: e,
    });
  }

  onChangePageSize(e) {
    const { onPageSizeChange } = this.props;
    const { onPageChange } = this.props;
    onPageChange(0, e.value);
    onPageSizeChange(e.value);
    this.setState({
      activePage: 1,
      selectedPageOption: e,
    });
  }

  render() {
    const {
      showPagePerSize,
      total,
    } = this.props;
    const {
      selectedPageOption, totalItem, activePage,
    } = this.state;
    return (
      <div className="Pagination-container">
        {showPagePerSize ? (
          <div className="page-per-size">
            <Select
              value={selectedPageOption}
              onChange={e => this.onChangePageSize(e)}
              options={pageOption}
              isSearchable={false}
              placeholder="10"
              className="select-pagination"
              menuPlacement="top"
            />
          </div>
        ) : <div />}
        <div className="number-item-per-page">
          {displayItemPagination(selectedPageOption.value, total, activePage)}
        </div>
        <div className="Table__pagination">
          {total > selectedPageOption.value && (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={selectedPageOption.value}
              totalItemsCount={totalItem}
              onChange={this.onChangePage}
              itemClass="page-item"
              linkClass="page-link"
            />
          )}
        </div>
      </div>
    );
  }
}

CustomPagination.propTypes = {
  onPageChange: PropTypes.any.isRequired,
  showPagePerSize: PropTypes.any.isRequired,
  total: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired,
};

CustomPagination.defaultProps = {
};
