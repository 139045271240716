import React from 'react';
import PropTypes from 'prop-types';
import Errors from '../Errors/index';

class Input extends React.PureComponent {
  render() {
    const {
      classStyle, name, errors, ...other
    } = this.props;

    return (
      <div className="input-username">
        <input
          {...other}
          {...this.props}
          noValidate="novalidate"
          className={classStyle}
        />
        {errors && errors[name] && <Errors {...this.props} errors={errors[name]} />}
      </div>
    );
  }
}

export default Input;
Input.propTypes = {
  classStyle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.any.isRequired,
};
